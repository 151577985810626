'use client';

import { Fragment, useEffect } from 'react';
import Modal from 'react-modal';

import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { useScrollBlock } from '@shared/hooks';

import TransitionComponent from './TransitionComponent';

interface IProps {
  isOpen: boolean;
  setIsOpen?: (arg: boolean) => void;
  onClose?: () => void;
  children: React.ReactNode;
  disableClose?: boolean;
  className?: string;
  dialogClassName?: string;
  overlayClassName?: string;
}

Modal.setAppElement('#app');

const ModalLayout = ({
  isOpen,
  setIsOpen,
  children,
  onClose,
  disableClose,
  className,
  dialogClassName,
  overlayClassName,
}: IProps) => {
  // scroll fix when have two or more modals attached to same element
  // closing one modal resets scrollbar behavior to unset
  // there are two options:
  // 1. use useEffect to block scroll base on open + set scroll-postion onAfter handlers
  // 2. use different elements to attach modals, but in case of multiple modals that can be quiet cumbersome
  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    if (isOpen) {
      blockScroll();
    }

    return () => {
      allowScroll();
    };
  }, [allowScroll, blockScroll, isOpen]);
  if (!isOpen) return null;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Modal
        isOpen={isOpen}
        className={twMerge('relative z-[100] p-3 xs:p-6', dialogClassName)}
        overlayElement={(_, contentElement) => (
          <>
            <TransitionComponent>
              <div
                className={twMerge(
                  'fixed inset-0 z-99 bg-neutral900 bg-opacity-50 backdrop-blur-[3px] transition-opacity',
                  overlayClassName
                )}
              />
            </TransitionComponent>
            <div
              className={twMerge(
                'fixed inset-0 bg-neutral900 bg-opacity-50 backdrop-blur-[3px] transition-opacity',
                overlayClassName
              )}
            />
            {contentElement}
          </>
        )}
        onRequestClose={() => {
          setIsOpen && !disableClose && setIsOpen(false);
          onClose?.();
        }}
      >
        <div className="fixed inset-0 z-99">
          <div className="flexCenter min-h-full text-center">
            <TransitionComponent>
              <div
                className={twMerge(
                  'roundedBrTl relative m-4 flex max-h-[90dvh] w-full max-w-37rem transform flex-col overflow-hidden border border-neutral700 bg-neutral800 p-6 text-left text-white shadow-4xl transition-all',
                  className
                )}
              >
                {children}
              </div>
            </TransitionComponent>
          </div>
        </div>
      </Modal>
    </Transition.Root>
  );
};

export default ModalLayout;
