import { FilterCheck } from '@shared/assets';

export const SelectedItem = ({
  value,
  label,
}: {
  value: string;
  label: string;
}) => {
  return (
    <div className="font-small flex items-center justify-between gap-[10px] font-bold">
      <span className="text-neutral400">{label}:</span>{' '}
      <span className="text-white">{value}</span>
    </div>
  );
};

export const OptionItem = ({
  value,
  selectedOption,
}: {
  value: string;
  selectedOption: string;
}) => {
  const isSelected = value === selectedOption;
  return (
    <div
      className={`font-small flex items-center justify-between gap-4 p-[10px] font-bold uppercase transition-all duration-300 ease-out ${isSelected ? 'bg-green900 text-white' : 'text-neutral200 active:bg-neutral700 hover:bg-neutral800'}`}
    >
      {value}{' '}
      {isSelected && <FilterCheck className="flex-shrink-0 text-white" />}
    </div>
  );
};
