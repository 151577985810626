'use client';

import { useEffect, useRef, useState } from 'react';

import { ActiveFilters, IDropdownOption } from '@interfaces';

import useGetMarketplaceProductsQuery from '@api/user/marketplace/queries/useGetMarketplaceProductsQuery';

import { QUERY_PARAM } from '@shared/constants';
import { usePagination, useUpsertQueryParams } from '@shared/hooks';

import AvaChat from '../avaChat';
import ProductsGrid from '../productsGrid';
import { SELECT_FILTER, getUpcomingProducts } from './constants';
import useProductFilters from './hooks/useProductFilters';
import ProductsFilterSidebar from './productsFilterSidebar';
import FilterTabs from './productsFilterTabs';

const ProductsFilterWrapper = () => {
  const { upsertQueryParams } = useUpsertQueryParams();
  const { page, updatePage } = usePagination();

  const {
    productFilterTypes,
    searchQuery,
    activeFilters,
    setActiveFilters,
    productType,
    setProductType,
    selectedSortOption,
    setSelectedSortOption,
    queryParams,
    sortBy,
    sortByDirection,
  } = useProductFilters();

  const [searchFilter, setSearchFilter] = useState(searchQuery);
  const searchRef = useRef<string>(queryParams.searchQueryParam || '');

  const { data: marketplaceProducts, isPending } =
    useGetMarketplaceProductsQuery({
      keyword: searchFilter || queryParams.searchQueryParam || '',
      page,
      productTypeIds: productType,
      categoryIds: activeFilters.category,
      tagIds: activeFilters.tags,
      sortBy,
      sortDirection: sortByDirection,
      ...(activeFilters.isFreeTrial && {
        isFreeTrial: activeFilters.isFreeTrial,
      }),
    });

  const shouldShowUpcomingProducts = () => {
    return (
      (!marketplaceProducts?.data.length ||
        marketplaceProducts?.meta.totalPages === page) &&
      !activeFilters.category.length &&
      !activeFilters.tags.length &&
      !activeFilters.isFreeTrial &&
      !searchFilter &&
      !queryParams.searchQueryParam
    );
  };

  const productsWithMock = [...(marketplaceProducts?.data || [])];

  if (shouldShowUpcomingProducts() && productType > 0) {
    productsWithMock.push(
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(getUpcomingProducts(productType) as any)
    );
  }

  const onTabChange = (tabId: number) => {
    upsertQueryParams([
      {
        key: QUERY_PARAM.PRODUCT_TYPE_IDS,
        value: tabId === 0 ? '' : tabId.toString(),
      },
      {
        key: QUERY_PARAM.TAGS,
        value: '',
      },
      {
        key: QUERY_PARAM.CATEGORY,
        value: '',
      },
      {
        key: QUERY_PARAM.SORT_BY,
        value: '',
      },
      {
        key: QUERY_PARAM.IS_FREE_TRIAL,
        value: '',
      },
      {
        key: QUERY_PARAM.PAGE,
        value: '',
      },
    ]);

    setProductType(tabId);
    setActiveFilters({ category: [], tags: [], isFreeTrial: '' });
    setSelectedSortOption(SELECT_FILTER[0]);
    updatePage({ disableQueryParams: true });
  };

  const onSelectSortChange = (option: IDropdownOption) => {
    setSelectedSortOption(option);
    updatePage({ disableQueryParams: true });

    upsertQueryParams([
      {
        key: QUERY_PARAM.SORT_BY,
        value: option.id === 'createdAt' ? '' : option.id.toString(),
      },
    ]);
  };

  const onSetActiveFilters = (newActiveFilters: ActiveFilters) => {
    setActiveFilters(newActiveFilters);
    updatePage({ disableQueryParams: true });

    upsertQueryParams([
      {
        key: QUERY_PARAM.CATEGORY,
        value: newActiveFilters.category.length
          ? newActiveFilters.category.join(',')
          : '',
      },
      {
        key: QUERY_PARAM.TAGS,
        value: newActiveFilters.tags.length
          ? newActiveFilters.tags.join(',')
          : '',
      },
      {
        key: QUERY_PARAM.IS_FREE_TRIAL,
        value: newActiveFilters.isFreeTrial,
      },
    ]);
  };

  useEffect(() => {
    const queryParams = [
      { key: QUERY_PARAM.SEARCH_QUERY, value: searchQuery || '' },
    ];

    if (
      (!searchRef.current && searchQuery) ||
      (searchRef.current && !searchQuery) ||
      (searchRef.current && searchQuery && searchRef.current !== searchQuery)
    ) {
      updatePage({ disableQueryParams: true });
      queryParams.push({ key: QUERY_PARAM.PAGE, value: '' });
    }

    if (searchQuery && productType === 0) {
      setProductType(1);
      queryParams.push({
        key: QUERY_PARAM.PRODUCT_TYPE_IDS,
        value: '1',
      });
    }

    upsertQueryParams(queryParams);
    setSearchFilter(searchQuery);
    searchRef.current = searchQuery || '';
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="products-section">
      <FilterTabs
        productFilterTypes={productFilterTypes}
        onChange={(tabId) => {
          onTabChange(tabId);
        }}
        activeTabId={productType}
      />
      {productType === 0 && <AvaChat />}

      {productType !== 0 && (
        <ProductsFilterSidebar
          activeTabId={productType}
          selectedSortOption={selectedSortOption}
          onSelectSortChange={onSelectSortChange}
          activeFilters={activeFilters}
          setActiveFilters={onSetActiveFilters}
          productTypeId={productType}
          totalItems={marketplaceProducts?.meta.totalItems}
        >
          <ProductsGrid
            page={page}
            onPageChange={updatePage}
            products={productsWithMock}
            totalPages={marketplaceProducts?.meta.totalPages}
            isLoadingData={isPending}
          />
        </ProductsFilterSidebar>
      )}
    </div>
  );
};

export default ProductsFilterWrapper;
