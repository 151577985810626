import SlotCounter from 'react-slot-counter';

import { formatNumbersWithK } from '@features/utils/numbers';
import { createStartingValueForCounter } from '@features/utils/strings';

interface IProps {
  statName: string;
  data: number;
}

const AISingleStat = ({ statName, data }: IProps) => {
  const endValue =
    data >= 1000
      ? formatNumbersWithK(data).toString().split('k')[0] + 'k'
      : data.toString();

  const startValue =
    data >= 1000
      ? createStartingValueForCounter(endValue)
      : '0'.repeat(data.toString().length);

  return (
    <div className="flex flex-col gap-3 text-center">
      <h3 className="heading-medium text-white">
        <SlotCounter
          startValue={startValue}
          value={endValue}
          animateUnchanged
          valueClassName="!align-top"
          separatorClassName="!align-top"
          charClassName="!align-top w-[21px]"
          direction="bottom-up"
          animateOnVisible
        />
      </h3>
      <p className="text-sm font-medium text-neutral300">{statName}</p>
    </div>
  );
};

export default AISingleStat;
