import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/app/src/features/heroSection/AIStatsOverview.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/heroSection/swiper/Swiper.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/marketplace/productsFilters/ProductsFilterWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/assets/index.ts")