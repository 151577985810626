import { useState } from 'react';

import {
  ActiveFilters,
  ProductFiltersResponse,
  TagsAndCategories,
} from '@interfaces';

import { QUERY_PARAM } from '@shared/constants';

import FilterItem from '../productsFilterItem';
import ProductsFilterCategoriesGroupModal from './ProductsFilterCategoriesGroupModal';
import ProductsFilterTagsGroupModal from './ProductsFilterTagsGroupModal';

interface IProps {
  title: keyof ActiveFilters;
  filters: ProductFiltersResponse;
  activeFilters: ActiveFilters;
  setActiveFilters: (activeFilters: ActiveFilters) => void;
  hasShowMore: boolean;
  activeTabId?: number;
}

const ProductsFilterGroup = ({
  title,
  filters,
  activeFilters,
  setActiveFilters,
  hasShowMore,
  activeTabId,
}: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowMoreClick = () => {
    setIsModalOpen(true);
  };

  const renderGroupModal = () => {
    switch (title) {
      case QUERY_PARAM.CATEGORY:
        return (
          <ProductsFilterCategoriesGroupModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            productTypeId={activeTabId}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
        );
      case QUERY_PARAM.TAGS:
        return (
          <ProductsFilterTagsGroupModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full border-b border-neutral700 pb-6 pl-6 pt-0 last:border-b-0 max-md:pr-6">
      <div className="mb-4 flex justify-between pr-4">
        <p className="label-large whitespace-nowrap pr-4">{title}</p>
        {hasShowMore && !isModalOpen && (
          <button
            onClick={() => handleShowMoreClick()}
            className="label-small whitespace-nowrap text-yellow500 underline decoration-transparent underline-offset-4 transition-all duration-300 ease-out hover:decoration-yellow500"
          >
            Show more
          </button>
        )}
      </div>

      {filters.data
        .slice(0, 5)
        ?.map((filter) => (
          <FilterItem
            key={filter.id}
            filter={filter}
            isSelected={
              !!activeFilters[title as keyof TagsAndCategories]?.some(
                (active) => active === filter.id
              )
            }
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            title={title}
          />
        ))}
      {isModalOpen && renderGroupModal()}
    </div>
  );
};
export default ProductsFilterGroup;
