'use client';

import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import ModalHeader from './ModalHeader';
import ModalLayout from './ModalLayout';

interface IProps {
  isOpen: boolean;
  children: React.ReactNode;
  modalTitle?: string | ReactNode;
  modalDescription?: string | ReactNode;
  setIsOpen?: (arg: boolean) => void;
  onClose?: () => void;
  hideCloseIcon?: boolean;
  disableClose?: boolean;
  titleStyles?: string;
  descriptionStyles?: string;
  modalContentClassName?: string;
  className?: string;
  dialogClassName?: string;
  overlayClassName?: string;
  modalHeaderClassName?: string;
  icon?: ReactNode;
}

const Modal = ({
  isOpen,
  setIsOpen,
  modalTitle,
  modalDescription,
  children,
  hideCloseIcon,
  disableClose,
  onClose,
  titleStyles,
  descriptionStyles,
  modalContentClassName,
  className,
  dialogClassName,
  overlayClassName,
  modalHeaderClassName,
  icon,
}: IProps) => {
  if (!isOpen) return null;

  return (
    <ModalLayout
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={onClose}
      disableClose={disableClose}
      className={className}
      dialogClassName={dialogClassName}
      overlayClassName={overlayClassName}
    >
      <ModalHeader
        modalTitle={modalTitle}
        modalDescription={modalDescription}
        titleStyles={titleStyles}
        descriptionStyles={descriptionStyles}
        hideCloseIcon={hideCloseIcon || disableClose}
        onClose={onClose}
        setIsOpen={setIsOpen}
        icon={icon}
        modalHeaderClassName={modalHeaderClassName}
      />
      <div
        className={twMerge(
          'scrollbarHide flex-grow overflow-y-auto',
          modalContentClassName
        )}
      >
        {children}
      </div>
    </ModalLayout>
  );
};

export default Modal;
