import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { CloseIcon } from '@shared/assets';

interface IModalHeaderProps {
  modalTitle?: string | ReactNode;
  modalDescription?: string | ReactNode;
  titleStyles?: string;
  descriptionStyles?: string;
  hideCloseIcon?: boolean;
  modalHeaderClassName?: string;
  closeButtonClassName?: string;
  setIsOpen?: (value: boolean) => void;
  icon?: ReactNode;
  onClose?: () => void;
}

const ModalHeader = ({
  modalTitle,
  modalDescription,
  titleStyles,
  descriptionStyles,
  onClose,
  setIsOpen,
  hideCloseIcon,
  modalHeaderClassName,
  icon,
}: IModalHeaderProps) => (
  <div
    className={twMerge(
      'mb-6',
      !modalTitle && !modalDescription && 'absolute right-6 mb-0',
      modalHeaderClassName
    )}
  >
    {icon && (
      <div className="flex w-full justify-between">
        <div className="flexCenter roundedBlTr mb-6 size-20 border border-neutral700 bg-neutral900">
          {icon}
        </div>
        <CloseIcon
          aria-hidden="true"
          className={twMerge(
            'ml-auto size-4.5 cursor-pointer self-start text-white',
            hideCloseIcon && 'hidden'
          )}
          onClick={() => {
            setIsOpen?.(false);
            onClose?.();
          }}
        />
      </div>
    )}
    <div className="relative flex flex-grow items-baseline justify-between">
      {modalTitle && (
        <div
          className={twMerge(
            typeof modalTitle === 'string' &&
              'font-xxlarge font-bold drop-shadow-3xl xs:whitespace-pre-line',
            hideCloseIcon && 'flex-grow',
            titleStyles
          )}
        >
          {modalTitle}
        </div>
      )}
      <CloseIcon
        aria-hidden="true"
        className={twMerge(
          'ml-auto size-[11.5px] cursor-pointer self-start text-white',
          (hideCloseIcon || !!icon) && 'hidden'
        )}
        onClick={() => {
          setIsOpen?.(false);
          onClose?.();
        }}
      />
    </div>
    {modalDescription && (
      <div
        className={twMerge('font-base mt-2 text-neutral200', descriptionStyles)}
      >
        {typeof modalDescription === 'string' ? (
          <p>{modalDescription}</p>
        ) : (
          modalDescription
        )}
      </div>
    )}
  </div>
);

export default ModalHeader;
