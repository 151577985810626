import { useQuery } from '@tanstack/react-query';

import { MARKETPLACE_TAGS } from '@shared/constants/queryKeys';

import { marketplaceApi } from '../marketplaceApi';

const useGetMarketplaceTagsQuery = () =>
  useQuery({
    queryKey: [MARKETPLACE_TAGS],
    queryFn: () => marketplaceApi.client.getMarketplaceTags(),
  });

export default useGetMarketplaceTagsQuery;
