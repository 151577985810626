import { useQuery } from '@tanstack/react-query';

import { HOMEPAGE_STATISTIC } from '@shared/constants/queryKeys';

import { marketplaceApi } from '../marketplaceApi';

const useGetHomepageStatisticQuery = () =>
  useQuery({
    queryKey: [HOMEPAGE_STATISTIC],
    queryFn: () => marketplaceApi.client.getHomepageStatistic(),
  });

export default useGetHomepageStatisticQuery;
