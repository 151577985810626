import { useQuery } from '@tanstack/react-query';

import { PRODUCT_CATEGORIES } from '@shared/constants/queryKeys';

import { marketplaceApi } from '../marketplaceApi';

const useGetProductCategoriesQuery = (productTypeId: number) =>
  useQuery({
    queryKey: [PRODUCT_CATEGORIES, productTypeId],
    queryFn: () => marketplaceApi.client.getProductCategories(productTypeId),
  });

export default useGetProductCategoriesQuery;
