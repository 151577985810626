import { Dispatch, SetStateAction, useState } from 'react';

import { ActiveFilters } from '@interfaces';

import useGetMarketplaceTagsQuery from '@api/user/marketplace/queries/useGetMarketplaceTagsQuery';

import Modal from '@components/modals/Modal';
import ModalFooter from '@components/modals/ModalFooter';

import { QUERY_PARAM } from '@shared/constants';

import FilterItem from '../productsFilterItem';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  activeFilters: ActiveFilters;
  setActiveFilters: (activFilters: ActiveFilters) => void;
}

const ProductsFilterTagsGroupModal = ({
  isModalOpen,
  setIsModalOpen,
  activeFilters,
  setActiveFilters,
}: IProps) => {
  const [selectedFilters, setSelectedFilters] =
    useState<ActiveFilters>(activeFilters);

  const { data: filters, isLoading } = useGetMarketplaceTagsQuery();

  const handleConfirmClick = () => {
    setActiveFilters(selectedFilters);
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      modalTitle="Filters"
      className="!max-w-22.5rem"
      modalContentClassName="border-t border-neutral700 pt-6"
    >
      <div className="flex flex-col">
        <h1 className="label-large mb-2 text-white">Tags</h1>
        <div className="max-h-[182px] overflow-y-scroll">
          {isLoading && (
            <div>
              {Array.from({ length: 6 }, (_, index) => (
                <div
                  key={index}
                  className="mb-1.5 h-6 w-full animate-pulse bg-neutral700 opacity-5"
                />
              ))}
            </div>
          )}
          {filters?.data.map((filter) => (
            <FilterItem
              key={filter.id}
              filter={filter}
              isSelected={
                !!selectedFilters[QUERY_PARAM.TAGS].some(
                  (active) => active === filter.id
                )
              }
              activeFilters={selectedFilters}
              setActiveFilters={setSelectedFilters}
              title={QUERY_PARAM.TAGS}
              isModalFilters
            />
          ))}
        </div>
        <ModalFooter
          confirmButtonProps={{
            text: 'Confirm',
            onClick: handleConfirmClick,
            type: 'button',
          }}
        />
      </div>
    </Modal>
  );
};

export default ProductsFilterTagsGroupModal;
