import { useQuery } from '@tanstack/react-query';

import { PRODUCT_TYPES_KEY } from '@shared/constants/queryKeys';

import { marketplaceApi } from '../marketplaceApi';

const useGetProductTypes = () =>
  useQuery({
    queryKey: PRODUCT_TYPES_KEY(),
    queryFn: () => marketplaceApi.client.getProductTypes(),
  });

export default useGetProductTypes;
