'use client';

import useGetHomepageStatisticQuery from '@api/user/marketplace/queries/useGetHomepageStatisticQuery';

import AISingleStat from './AISingleStat';

const AIStatsOverview = () => {
  const { data: statisticData } = useGetHomepageStatisticQuery();

  return (
    <div className="fixed -top-full w-full max-w-25.5rem justify-between rounded-bl-[12px] rounded-tr-[12px] border border-neutral700 bg-neutral900 p-6 text-center lg:relative lg:flex">
      <AISingleStat
        statName="AI Products"
        data={statisticData?.data.totalProducts || 0}
      />
      <AISingleStat
        statName="AI Companies"
        data={statisticData?.data.totalCompanies || 0}
      />
      <AISingleStat
        statName="Upcoming Apps"
        data={statisticData?.data.totalUpcomingApps || 50}
      />
    </div>
  );
};

export default AIStatsOverview;
