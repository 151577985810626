import { Dispatch, SetStateAction } from 'react';

import { ISvgImport } from '@interfaces/svg';
import { twMerge } from 'tailwind-merge';

interface IProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  className: string;
  Icon: ISvgImport;
}

const FilterToggleButton = ({ title, setIsOpen, Icon, className }: IProps) => {
  return (
    <button
      className={twMerge(
        'group flex h-max items-center gap-2.5 border border-neutral700 px-4 py-3 text-neutral300 transition-all duration-300 ease-out hover:bg-neutral900 hover:text-white',
        className
      )}
      onClick={() => setIsOpen((isOpen) => !isOpen)}
    >
      <p className="label-large whitespace-nowrap !leading-none">{title}</p>
      <Icon className="size-4 group-hover:*:fill-white" />
    </button>
  );
};
export default FilterToggleButton;
