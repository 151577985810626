'use client';

import { isBrowser } from 'react-device-detect';
import { Flipper } from 'react-flip-toolkit';

import {
  PlaceholderProductData,
  UpdatePage,
  UserProductListResponse,
} from '@interfaces';
import { twMerge } from 'tailwind-merge';

import EmptyContent from '@components/emptyContent';
import GridPagination from '@components/gridPagination';
import { Loading } from '@components/loading';
import ProductCard from '@components/productCard';
import ProductPlaceholderCard from '@components/productPlaceholderCard/ProductPlaceholderCard';

import { SearchIcon } from '@shared/assets';

interface IProps {
  page: number;
  onPageChange: ({ newPage, disableQueryParams }: UpdatePage) => void;
  isSidebarOpen?: boolean;
  products?: UserProductListResponse['data'];
  totalPages?: number;
  isLoadingData: boolean;
}

const ProductsGrid = ({
  page,
  onPageChange,
  isSidebarOpen,
  products,
  totalPages,
  isLoadingData,
}: IProps) => {
  const FloatingCircle = () => (
    <div className="absolute -right-20 top-72 hidden size-[26rem] rounded-full bg-brandPrimary opacity-10 blur-100px lg:block" />
  );

  if (isLoadingData) {
    return <Loading className="mx-6 mb-16 h-[304px] flex-1" />;
  }

  if (!(products && products?.length > 0))
    return (
      <EmptyContent
        title="No results found"
        icon={SearchIcon}
        className="mx-6 mb-16 h-[304px] flex-1"
        onIconClick={() => {
          const targetElement = document.getElementById('search-id');
          targetElement?.scrollIntoView({ behavior: 'smooth' });
          setTimeout(() => {
            targetElement?.focus();
          }, 800);
        }}
      />
    );

  const shouldAnimate = isBrowser && isSidebarOpen;

  return (
    <>
      <FloatingCircle />
      <Flipper
        flipKey={shouldAnimate}
        spring={{ stiffness: 500, damping: 45 }}
        className="w-full flex-1 will-change-transform"
      >
        <div
          className={twMerge(
            'px-6 pb-16 xl:pl-10 xl:pr-22.5',
            isSidebarOpen
              ? `md:w-[calc(100vw_-_250px)] xl:w-[calc(100vw_-_300px)]`
              : `w-[100vw]`
          )}
        >
          <div
            className={twMerge(
              'grid grid-cols-1 gap-11 sm:grid-cols-[repeat(auto-fill,minmax(320px,_1fr))] 3xl:grid-cols-5'
            )}
          >
            {products?.map((product) =>
              product.isPlaceholder ? (
                <ProductPlaceholderCard
                  key={product.id}
                  product={product as unknown as PlaceholderProductData}
                />
              ) : (
                <ProductCard key={product.id} product={product} />
              )
            )}
          </div>
          {totalPages && totalPages > 1 ? (
            <GridPagination
              currentPage={page}
              pageCount={totalPages || 0}
              onBackClick={() =>
                onPageChange({
                  newPage: page - 1,
                })
              }
              onForwardClick={() =>
                onPageChange({
                  newPage: page + 1,
                })
              }
              onPageChange={(pageNum) =>
                onPageChange({
                  newPage: pageNum,
                })
              }
            />
          ) : null}
        </div>
      </Flipper>
    </>
  );
};

export default ProductsGrid;
