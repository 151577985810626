import { Dispatch, Fragment, SetStateAction, useState } from 'react';

import { ActiveFilters } from '@interfaces';

import useGetProductCategoriesQuery from '@api/user/marketplace/queries/useGetProductCategoriesQuery';

import Modal from '@components/modals/Modal';
import ModalFooter from '@components/modals/ModalFooter';

import { QUERY_PARAM } from '@shared/constants';

import FilterItem from '../productsFilterItem';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  productTypeId?: number;
  activeFilters: ActiveFilters;
  setActiveFilters: (activFilters: ActiveFilters) => void;
}

const ProductsFilterCategoriesGroupModal = ({
  isModalOpen,
  setIsModalOpen,
  productTypeId,
  activeFilters,
  setActiveFilters,
}: IProps) => {
  const [selectedFilters, setSelectedFilters] = useState<ActiveFilters>(
    () => activeFilters
  );

  const { data: filters, isLoading } = useGetProductCategoriesQuery(
    productTypeId || 1
  );

  const handleConfirmClick = () => {
    setActiveFilters(selectedFilters);
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      modalTitle="Filters"
      className="!max-w-22.5rem"
      modalContentClassName="border-t border-neutral700 pt-6"
    >
      <div className="flex flex-col">
        <h1 className="label-large mb-2 text-white">{'Category'}</h1>
        <div className="max-h-[182px] overflow-y-scroll">
          {isLoading && (
            <div>
              {Array.from({ length: 6 }, (_, index) => (
                <div
                  key={index}
                  className="mb-1.5 h-6 w-full animate-pulse bg-neutral700 opacity-5"
                />
              ))}
            </div>
          )}
          {filters?.data.map((filter) => (
            <Fragment key={filter.id}>
              <FilterItem
                key={filter.id}
                filter={filter}
                isSelected={
                  !!selectedFilters[QUERY_PARAM.CATEGORY].some(
                    (active) => active === filter.id
                  )
                }
                activeFilters={selectedFilters}
                setActiveFilters={setSelectedFilters}
                title={QUERY_PARAM.CATEGORY}
                isModalFilters
              />
            </Fragment>
          ))}
        </div>
        <ModalFooter
          confirmButtonProps={{
            text: 'Confirm',
            onClick: handleConfirmClick,
            type: 'button',
          }}
        />
      </div>
    </Modal>
  );
};

export default ProductsFilterCategoriesGroupModal;
