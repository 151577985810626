export const layer1UpcomingProducts = [
  'Audiogen',
  'Musicgen',
  'StableDiffusionXL',
  'WhisperAI',
  'Flux',
  'Llama 3.2 90b',
];

export const layer2UpcomingProducts = [
  'QuantumFlow',
  'DeepScan',
  'Sentient',
  'LucidPath',
  'OmniSense',
  'Cerebri',
  'VortexAI',
  'MetaMind',
  'TrueVision',
  'AquaSynth',
  'InsightX',
  'NeuroPulse',
  'Intellica',
  'MediVerse',
];

export const layer3UpcomingProducts = [
  'CogniLink',
  'SkySynth',
  'VisionaryAI',
  'NexusMind',
  'BioSphere',
  'PixelShift',
  'NextSage',
  'AstraMind',
  'Neuronet',
  'EchoLogic',
  'SenseIQ',
  'TechFlux',
  'QuantumEye',
  'MetaSolve',
];

export const layer4UpcomingProducts = [
  'AivaFlow',
  'LuminaCore',
  'CoreVision',
  'Infinus',
  'PsyAI',
  'BioMimic',
  'LogicX',
  'CybriSense',
  'EchoMind',
  'Auralyn',
  'SpectraBot',
  'Synthora',
  'AxionIQ',
  'VistaMind',
];
