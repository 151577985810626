import {
  ActiveFilters,
  ProductFilterData,
  TagsAndCategories,
} from '@interfaces';
import { twMerge } from 'tailwind-merge';

import { FilterCheck } from '@shared/assets';
import { useUpdateUrlWithObjectQueryParams } from '@shared/hooks';

interface Props {
  filter: ProductFilterData;
  isSelected: boolean;
  activeFilters: ActiveFilters;
  setActiveFilters: (activFilters: ActiveFilters) => void;

  title: keyof ActiveFilters;
  isModalFilters?: boolean;
}

const ProductsFilterItem = ({
  filter,
  isSelected,
  activeFilters,
  setActiveFilters,
  title,
  isModalFilters,
}: Props) => {
  const updateUrlWithQueryParams = useUpdateUrlWithObjectQueryParams();

  const onFilterClick = () => {
    let updatedMyActiveFilters;
    if (activeFilters[title as keyof TagsAndCategories].includes(filter.id)) {
      const updatedValues = activeFilters[
        title as keyof TagsAndCategories
      ].filter((item) => item !== filter.id);

      updatedMyActiveFilters = {
        ...activeFilters,
        [title]: updatedValues,
      };
    } else {
      const updatedValues = [
        ...activeFilters[title as keyof TagsAndCategories],
        filter.id,
      ];
      updatedMyActiveFilters = {
        ...activeFilters,
        [title]: updatedValues,
      };
    }
    //we will update urlParams on btn click inside ProductsFilterGroupModal
    !isModalFilters &&
      updateUrlWithQueryParams({ queryValues: updatedMyActiveFilters });
    setActiveFilters(updatedMyActiveFilters);
  };

  return (
    <button
      onClick={() => onFilterClick()}
      className={twMerge(
        'flex w-full items-center justify-between overflow-hidden px-4 py-2 text-left font-medium text-neutral300 transition duration-300 ease-out hover:text-white',
        isModalFilters ? 'hover:bg-neutral900' : 'hover:bg-neutral800',
        isSelected &&
          (isModalFilters
            ? 'bg-neutral900 text-white'
            : 'bg-neutral800 text-white')
      )}
    >
      <span className="overflow-hidden text-ellipsis whitespace-nowrap">
        {filter.name}
      </span>
      {isSelected && <FilterCheck className="text-green500" />}
    </button>
  );
};

export default ProductsFilterItem;
