import Image from 'next/image';

import { UserGetProductResPublisherData } from '@interfaces';

import CompanyAvatar from '@components/companyAvatar';

interface IProps {
  productImage: string;
  productName: string;
  publisher: UserGetProductResPublisherData['user'];
}

const ImageComponent = ({ productImage }: { productImage: string }) => {
  return (
    <div className="swiperPolygonNotch flexCenter size-full bg-neutral700 p-0.5 text-white">
      <div className="swiperPolygonNotch size-full min-h-56">
        <Image
          src={productImage}
          alt="image"
          className="size-full object-cover"
          fill
        />
      </div>
    </div>
  );
};

const SwiperSlideElement = ({
  productImage,
  productName,
  publisher,
}: IProps) => {
  return (
    <div className="mx-auto flex h-full w-full flex-col lg:max-w-[800px]">
      <ImageComponent productImage={productImage} />
      <div className="flex flex-col items-start justify-between gap-4 py-4 lg:flex-row lg:gap-16 lg:pt-6">
        <h5 className="heading-xxsmall line-clamp-2 w-full text-white lg:pl-6">
          {productName}
        </h5>
        <div className="flex w-[90%] gap-2 lg:justify-end">
          <CompanyAvatar user={publisher} disableLink />
        </div>
      </div>
    </div>
  );
};

export default SwiperSlideElement;
