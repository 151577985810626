import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import {
  ActiveFilters,
  ProductFilterData,
  TagsAndCategories,
} from '@interfaces';

import useGetMarketplaceTagsQuery from '@api/user/marketplace/queries/useGetMarketplaceTagsQuery';
import useGetProductCategoriesQuery from '@api/user/marketplace/queries/useGetProductCategoriesQuery';

import { RemoveIcon, RemoveIconCircled } from '@shared/assets';
import { QUERY_PARAM } from '@shared/constants';
import { useUpdateUrlWithObjectQueryParams } from '@shared/hooks';

interface IProps {
  activeFilters: ActiveFilters;
  setActiveFilters: (activFilters: ActiveFilters) => void;
  activeTabId?: number;
}

const SelectedProductsFilters = ({
  activeFilters,
  activeTabId,
  setActiveFilters,
}: IProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const updateUrlWithQueryObjectParams = useUpdateUrlWithObjectQueryParams();

  const { data: productCategories } = useGetProductCategoriesQuery(
    activeTabId || 1
  );
  const { data: marketplaceTags } = useGetMarketplaceTagsQuery();
  const filterCategories = [
    {
      ...productCategories,
      categoryName: QUERY_PARAM.CATEGORY,
    },
    { ...marketplaceTags, categoryName: QUERY_PARAM.TAGS },
  ];

  const updateURLParams = (queryName: string, queryValues: number[]) => {
    const params = new URLSearchParams(searchParams);

    if (queryValues.length > 0) {
      params.set(queryName, queryValues.join(','));
    } else {
      params.delete(queryName);
    }

    router.replace(`${pathname}?${params.toString()}`, { scroll: false });
  };

  const categoriesAndTags = {
    category: activeFilters.category,
    tags: activeFilters.tags,
  };

  const getActiveFiltersByCategory = (
    title: keyof TagsAndCategories,
    items?: ProductFilterData[]
  ) => {
    const activeTitles: string[] = [];

    categoriesAndTags[title]?.forEach((activeFilter) => {
      const matchingItem = items?.find((item) => item.id === activeFilter);
      if (matchingItem) {
        activeTitles.push(matchingItem.name);
      }
    });

    return activeTitles.join(', ');
  };

  const onClearFilters = (categoryName?: string) => {
    if (categoryName) {
      const filterCategory = filterCategories.find(
        (tab) => tab.categoryName === categoryName
      );
      const itemIdsInCategory = filterCategory?.data?.map((item) => item.id);

      const filteredActiveFilters = categoriesAndTags[
        categoryName.toLowerCase() as keyof TagsAndCategories
      ].filter((filter) => {
        return !itemIdsInCategory?.includes(filter);
      });

      setActiveFilters({
        ...activeFilters,
        [categoryName.toLowerCase()]: filteredActiveFilters,
        isFreeTrial: '',
      });

      updateUrlWithQueryObjectParams({
        queryName: categoryName.toLowerCase(),
        queryValues: activeFilters,
      });
      updateURLParams(categoryName.toLowerCase(), filteredActiveFilters);
    } else {
      setActiveFilters({ category: [], tags: [], isFreeTrial: '' });
      updateUrlWithQueryObjectParams({
        queryValues: { category: [], tags: [], isFreeTrial: '' },
      });
    }
  };

  return (
    <div className="scrollbarHide ml-6 flex w-max overflow-x-auto">
      <div className="flex w-full flex-wrap justify-end gap-2.5">
        {filterCategories.map((item) => {
          const activeFiltersByCategory = getActiveFiltersByCategory(
            item.categoryName as keyof TagsAndCategories,
            item.data
          );
          if (!activeFiltersByCategory.length) return;

          return (
            <div
              key={item.categoryName}
              className="customTransition group flex w-max max-w-full items-center border border-neutral700 pl-1.5 hover:border-neutral600 hover:bg-neutral800"
            >
              <p className="label-small mr-1.5 text-neutral300">
                {item.categoryName}
              </p>
              <div className="flex overflow-hidden bg-green900 px-1.5 py-1">
                <p className="label-small customTransition overflow-hidden text-ellipsis whitespace-nowrap text-green700 group-hover:text-green500 md:max-w-41.5rem md:text-nowrap">
                  {activeFiltersByCategory}
                  &nbsp;
                </p>
              </div>
              <button
                className="w-6 flex-shrink-0 px-1.5 py-2"
                onClick={() => onClearFilters(item.categoryName)}
              >
                <RemoveIconCircled className="customTransition size-full text-neutral300 group-hover:text-white" />
              </button>
            </div>
          );
        })}
        {activeFilters[QUERY_PARAM.CATEGORY]?.length ||
        activeFilters[QUERY_PARAM.IS_FREE_TRIAL] ||
        activeFilters[QUERY_PARAM.TAGS]?.length ? (
          <button
            onClick={() => onClearFilters()}
            className="customTransition label-small ml-3.5 flex items-center gap-2 !leading-[inherit] text-neutral300 hover:text-white"
          >
            <RemoveIcon />
            Clear all
          </button>
        ) : null}
      </div>
    </div>
  );
};
export default SelectedProductsFilters;
