'use client';

import { ComponentType, ElementType, useState } from 'react';

import { IDropdownOption } from '@interfaces';
import { twMerge } from 'tailwind-merge';

import { OptionItem, SelectedItem } from '@components/CustomDropdownItems';

import DropdownContainer from './DropdownContainer';

interface IProps {
  selectedOption?: IDropdownOption;
  options: IDropdownOption[];
  onChange: (arg0: IDropdownOption) => void;
  selectedItem?: ElementType | ComponentType;
  optionItem?: ElementType | ComponentType;
  dropdownBtnClassName?: string;
  selectedItemLabel: string;
  customIcon?: React.ReactNode;
  placeholderValue?: string;
  highlightSelected?: boolean;
}

const SingleSelectDropdown = ({
  options,
  selectedItem: SelectedItemElement = SelectedItem,
  optionItem: OptionItemElement = OptionItem,
  selectedOption,
  onChange,
  dropdownBtnClassName,
  selectedItemLabel,
  customIcon,
  placeholderValue,
  highlightSelected,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: IDropdownOption) => {
    onChange(option);
    setIsOpen(false);
  };

  const placeholderOption = { id: '', value: placeholderValue ?? 'SELECT' };

  const displayOption = selectedOption || placeholderOption;

  return (
    <DropdownContainer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      selectedOptions={
        <SelectedItemElement
          value={displayOption.value}
          label={selectedItemLabel}
        />
      }
      customIcon={customIcon}
      dropdownBtnClassName={dropdownBtnClassName}
    >
      {options.map((option) => (
        <li
          onClick={() => handleOptionClick(option)}
          key={option.id}
          className={twMerge(
            highlightSelected && option.id === selectedOption?.id
              ? 'bg-green900 text-white'
              : ''
          )}
        >
          <OptionItemElement
            value={option.value}
            selectedOption={displayOption.value}
          />
        </li>
      ))}
    </DropdownContainer>
  );
};

export default SingleSelectDropdown;
