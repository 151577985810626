import Image from 'next/image';
import { Flipped } from 'react-flip-toolkit';

import { PlaceholderProductData } from '@interfaces/marketplace/products';

import Ellipsis from '@components/Ellipsis';
import CompanyAvatar from '@components/companyAvatar';
import ProductCardTag from '@components/productCard/ProductCardTag';

import { AitechTokenImage } from '@shared/assets';
import { USDAI_TOKEN } from '@shared/constants/siteInfo';

interface ProductPlaceholderCardProps {
  product: PlaceholderProductData;
}

const ProductPlaceholderCard = ({ product }: ProductPlaceholderCardProps) => {
  return (
    <Flipped flipId={product.id}>
      <div className="roundedBrTl transitionColor group pointer-events-none relative z-0 h-[428px] border border-neutral700 will-change-transform md:h-[492px]">
        <div className="productCardImageGradient relative h-44 w-full">
          <Image
            src={product.thumbnail!}
            alt={product.name}
            unoptimized
            quality={10}
            fill
            className="rounded-tl-lg object-cover"
          />
        </div>
        <div className="flex h-[250px] min-h-[186px] flex-col rounded-br-lg px-6 pb-6 md:h-[314px]">
          <div className="relative bottom-4 flex flex-col gap-3 blur-sm">
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <CompanyAvatar user={{ name: 'Publisher' } as any} />
            <Ellipsis
              title={product.name}
              className="heading-xxsmall relative z-99"
              hideIcon
            />
            <div className="flex flex-wrap gap-3">
              <ProductCardTag tag="Upcoming" />
            </div>
          </div>

          <div className="flex items-center">
            <h1 className="mb-4 animate-pulse font-medium text-green400">
              <span className="relative">
                REVEALING SOON!!!
                <span className="absolute inset-0 animate-pulse bg-green400 opacity-75 blur-md"></span>
              </span>
            </h1>
          </div>
          <div className="mt-auto">
            <div className="mb-4 h-px w-full bg-neutral700" />
            <div className="flex items-center justify-between">
              <div className="blur-sm">
                <div className="align-center flex items-center gap-1">
                  <Image
                    src={AitechTokenImage}
                    alt="AITECH token"
                    className="size-5"
                  />
                  <p className="label-xlarge tracking-[0.52px] text-white">
                    {USDAI_TOKEN}
                  </p>{' '}
                  <p className="label-xlarge tracking-[0.52px] text-green500">
                    --
                  </p>
                </div>
              </div>

              <p className="font-xsmall font-bold tracking-[0.44px] text-neutral500 blur-sm">{`- VISITORS`}</p>
            </div>
          </div>
        </div>
      </div>
    </Flipped>
  );
};

export default ProductPlaceholderCard;
