import { UserProductListParams } from '@interfaces/marketplace/products';
import { useQuery } from '@tanstack/react-query';

import { PRODUCT_LIST_KEY } from '@shared/constants/queryKeys';

import { marketplaceApi } from '../marketplaceApi';

const useGetMarketplaceProductsQuery = (params?: UserProductListParams) => {
  return useQuery({
    queryKey: PRODUCT_LIST_KEY(params),
    queryFn: () => marketplaceApi.client.getProductsList(params),
    enabled: params?.productTypeIds !== 0,
  });
};

export default useGetMarketplaceProductsQuery;
