import { IProductsFilterTab } from '@interfaces';

import ProductsFilterTab from '../productsFilterTab';

interface Props {
  productFilterTypes?: IProductsFilterTab[];
  activeTabId?: number;
  onChange: (tabId: number) => void;
}

const ProductsFilterTabs = ({
  productFilterTypes,
  activeTabId,
  onChange,
}: Props) => {
  return (
    <div className="border-t border-neutral700 md:border-b">
      <div className="mx-auto grid w-full max-w-[1200px] grid-cols-2 border-neutral700 md:grid-cols-5 md:border-b md:border-l">
        {productFilterTypes?.map(({ id, title, Icon }) => (
          <ProductsFilterTab
            key={id}
            id={id}
            Icon={Icon}
            title={title}
            onChange={onChange}
            activeTabId={activeTabId}
          />
        ))}
      </div>
    </div>
  );
};
export default ProductsFilterTabs;
