import { useSearchParams } from 'next/navigation';

import { QUERY_PARAM } from '@shared/constants/query';

export const getFiltersFromSearchParams = (searchParams: {
  get: (key: string) => string | null | undefined;
}) => {
  const productTypeIdsParam =
    searchParams.get(QUERY_PARAM.PRODUCT_TYPE_IDS) || 0;

  const categoryParam = searchParams.get(QUERY_PARAM.CATEGORY)
    ? decodeURIComponent(searchParams.get(QUERY_PARAM.CATEGORY)!)
        .split(',')
        .map(Number)
    : [];
  const tagsParam = searchParams.get(QUERY_PARAM.TAGS)
    ? decodeURIComponent(searchParams.get(QUERY_PARAM.TAGS)!)
        .split(',')
        .map(Number)
    : [];

  const sortByQueryParam = searchParams.get(QUERY_PARAM.SORT_BY)
    ? decodeURIComponent(searchParams.get(QUERY_PARAM.SORT_BY)!)
    : 'createdAt:DESC';

  const searchQueryParam = searchParams.get(QUERY_PARAM.SEARCH_QUERY)
    ? decodeURIComponent(searchParams.get(QUERY_PARAM.SEARCH_QUERY)!)
    : '';

  const page = searchParams.get(QUERY_PARAM.PAGE) || 1;

  const isFreeTrial = searchParams.get(QUERY_PARAM.IS_FREE_TRIAL) || '';

  return {
    productTypeIdsParam: Number(productTypeIdsParam),
    categoryParam,
    tagsParam,
    sortByQueryParam,
    searchQueryParam,
    page,
    isFreeTrial,
  };
};

const useProductFilterParams = () => {
  const searchParams = useSearchParams();

  return getFiltersFromSearchParams(searchParams);
};

export default useProductFilterParams;
