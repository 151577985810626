'use client';

import { Dispatch, SetStateAction } from 'react';

import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '@shared/assets';
import { useOutsideClick } from '@shared/hooks';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedOptions: string | JSX.Element;
  children: React.ReactNode;
  customIcon?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  dropdownBtnClassName?: string;
}

const DropdownContainer = ({
  placeholder,
  selectedOptions,
  children,
  isOpen,
  setIsOpen,
  disabled,
  dropdownBtnClassName,
  customIcon,
}: IProps) => {
  const dropdownRef = useOutsideClick(() => setIsOpen(false));

  return (
    <div
      ref={dropdownRef}
      className={twMerge(
        'roundedBlTr relative flex cursor-pointer items-center'
      )}
    >
      <button
        type="button"
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
        className={twMerge(
          'roundedBlTr group flex h-10 w-full items-center justify-between gap-2.5 whitespace-nowrap border px-3 uppercase transition-all duration-300 ease-out',
          !isOpen
            ? 'border-neutral700 bg-transparent enabled:hover:border-neutral600 enabled:hover:bg-neutral1000'
            : 'border-green800 bg-neutral1000 hover:bg-neutral1000',
          'disabled:cursor-not-allowed disabled:border-neutral100 disabled:opacity-20',
          'active:enabled:border-neutral300',
          'hover:enabled:active:border-neutral300',
          dropdownBtnClassName
        )}
      >
        {selectedOptions || placeholder}
        {customIcon ?? (
          <ChevronDownIcon
            className={twMerge(
              'size-4',
              'text-neutral400 transition-all',
              isOpen && 'rotate-180'
            )}
          />
        )}
      </button>
      {isOpen && (
        <ul
          className={twMerge(
            'roundedBlTr absolute left-0 top-12 z-10 w-full border border-neutral700 bg-neutral1000 py-1'
          )}
        >
          {children}
        </ul>
      )}
    </div>
  );
};

export default DropdownContainer;
