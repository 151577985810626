import { Fragment } from 'react';
import { Transition } from '@headlessui/react';

interface IProps {
  children: React.ReactNode;
  show?: boolean;
  testId?: string;
}

const TransitionComponent = ({ children, testId, show = true }: IProps) => {
  return (
    <div data-testid={testId} className="w-full flex justify-center">
      <Transition
        as={Fragment}
        show={show}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        {children}
      </Transition>
    </div>
  );
};

export default TransitionComponent;
