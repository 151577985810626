import { twMerge } from 'tailwind-merge';

import { FilterCheck } from '@shared/assets';

export const MultiSelectOptionItem = ({
  value,
  isSelected,
}: {
  value: string;
  isSelected: boolean;
}) => {
  return (
    <div
      className={twMerge(
        'font-small flex items-center justify-between p-[10px] font-bold uppercase transition-all duration-300 ease-out',
        isSelected
          ? 'bg-green900 text-white'
          : 'text-neutral200 active:bg-neutral700 hover:bg-neutral800'
      )}
    >
      {value} {isSelected && <FilterCheck className="text-white" />}
    </div>
  );
};
