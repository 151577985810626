import {
  layer1UpcomingProducts,
  layer2UpcomingProducts,
  layer3UpcomingProducts,
  layer4UpcomingProducts,
} from '@app/(user)/constants';
import {
  IDropdownOption,
  IProductsFilterTab,
  ISvgImport,
  PlaceholderProductData,
  ProductType,
} from '@interfaces';

import {
  AvaChatIcon,
  DatasetsIcon,
  Layer1Icon,
  Layer2Icon,
  Layer3Icon,
} from '@shared/assets';
import config from '@shared/config';

export const PAGE = 'page';
export const SEARCH_QUERY = 'searchQuery';
export const SORT_BY = 'sortBy';
export const PRODUCT_TYPE_IDS = 'productTypeIds';
export const CATEGORY = 'category';
export const TAGS = 'tags';

const filterTypesIcons: { [key: number]: ISvgImport } = {
  1: Layer1Icon,
  2: Layer2Icon,
  3: Layer3Icon,
  4: DatasetsIcon,
};

export const createProductFilterTypes = (
  productTypes: ProductType[] = []
): IProductsFilterTab[] => {
  const avachatType: IProductsFilterTab = {
    id: 0,
    title: 'Avachat',
    Icon: AvaChatIcon,
  };

  const dynamicTypes: IProductsFilterTab[] = productTypes.map((type) => ({
    id: type.id,
    title: type.name,
    Icon: filterTypesIcons[type.id] || Layer1Icon,
  }));

  return [avachatType, ...dynamicTypes];
};

export const SELECT_FILTER: IDropdownOption[] = [
  { id: 'createdAt:DESC', value: 'Newest First' },
  { id: 'numVisitor:DESC', value: 'Most Viewed' },
];

export const getUpcomingProducts = (
  productType: number
): PlaceholderProductData[] => {
  let baseIndex: number;
  let upcomingProducts: string[];

  switch (productType) {
    case 1:
      baseIndex = 0;
      upcomingProducts = layer1UpcomingProducts;

      break;
    case 2:
      baseIndex = layer1UpcomingProducts.length;
      upcomingProducts = layer2UpcomingProducts;

      break;
    case 3:
      baseIndex = layer1UpcomingProducts.length + layer2UpcomingProducts.length;
      upcomingProducts = layer3UpcomingProducts;

      break;
    case 4:
      baseIndex =
        layer1UpcomingProducts.length +
        layer2UpcomingProducts.length +
        layer3UpcomingProducts.length;
      upcomingProducts = layer4UpcomingProducts;

      break;
    default:
      upcomingProducts = layer1UpcomingProducts;
  }

  return upcomingProducts.map((product, index) => ({
    isPlaceholder: true,
    id: `upcoming-${productType}-${index}`,
    name: `${product}`,
    thumbnail: `${config.s3bucketUrl}/upcoming-apps/${baseIndex + index + 1}.png`,
  }));
};
