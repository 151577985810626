import { useEffect, useState } from 'react';

import { ActiveFilters, ProductType } from '@interfaces';
import { useQuery } from '@tanstack/react-query';

import useGetProductTypes from '@api/user/marketplace/queries/useGetProductTypes';

import { QUERY_PARAM } from '@shared/constants';
import { PRODUCT_SEARCH_INPUT } from '@shared/constants/queryKeys';
import { useUpsertQueryParams } from '@shared/hooks';

import { SELECT_FILTER, createProductFilterTypes } from '../constants';
import useProductFilterParams from './useProductFilterParams';

const useProductFilters = () => {
  const queryParams = useProductFilterParams();
  const {
    productTypeIdsParam,
    categoryParam,
    tagsParam,
    sortByQueryParam,
    isFreeTrial,
  } = queryParams;

  const { data: productTypes } = useGetProductTypes();

  const { data: searchQuery } = useQuery<string>({
    queryKey: [PRODUCT_SEARCH_INPUT],
  });

  const productTypesData = productTypes?.data.sort(
    (a: ProductType, b: ProductType) => a.id - b.id
  );

  const productFilterTypes = createProductFilterTypes(productTypesData);

  const [productType, setProductType] = useState(() => {
    const typeId = productFilterTypes?.find(
      (pt) => pt.id === productTypeIdsParam
    )?.id;
    return typeId ? typeId : 0;
  });

  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
    category: categoryParam || [],
    tags: tagsParam || [],
    isFreeTrial: isFreeTrial,
  });

  const [selectedSortOption, setSelectedSortOption] = useState(
    SELECT_FILTER.find((filter) => filter.id === sortByQueryParam) ||
      SELECT_FILTER[0]
  );

  const [sortBy, sortByDirection] = selectedSortOption.id.toString().split(':');
  const { upsertQueryParams } = useUpsertQueryParams();

  //Only triggers if someone manually changes the tab ID to a non-existent one
  useEffect(() => {
    const shouldResetParams = !productFilterTypes.some(
      (pt) => pt.id === productTypeIdsParam
    );

    if (shouldResetParams) {
      upsertQueryParams([
        {
          key: QUERY_PARAM.PRODUCT_TYPE_IDS,
          value: '',
        },
        {
          key: QUERY_PARAM.TAGS,
          value: '',
        },
        {
          key: QUERY_PARAM.CATEGORY,
          value: '',
        },
        {
          key: QUERY_PARAM.SORT_BY,
          value: '',
        },
        {
          key: QUERY_PARAM.IS_FREE_TRIAL,
          value: '',
        },
        {
          key: QUERY_PARAM.PAGE,
          value: '',
        },
      ]);
    }
  }, [productTypeIdsParam, productFilterTypes, upsertQueryParams]);

  return {
    productFilterTypes,

    searchQuery,

    activeFilters,
    setActiveFilters,

    productType,
    setProductType,

    selectedSortOption,
    setSelectedSortOption,

    sortBy,
    sortByDirection,

    queryParams,
  };
};

export default useProductFilters;
