import { useState } from 'react';

import { FeaturedProductsData } from '@interfaces';
import { SwiperClass } from 'swiper/react';
import { twMerge } from 'tailwind-merge';

interface IProps {
  activeIndex: number;
  swiperRef: React.MutableRefObject<SwiperClass | undefined>;
  data?: FeaturedProductsData[];
}

// FIXME: On mobile version it needs fine tuning. The scaling is off on mobile
// and the lines aren't centered properly.
// FIXME: Extract custom values to a config file.

const SwiperNavigation = ({ activeIndex, swiperRef, data }: IProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const numberOfLines =
    (data?.length ?? 0) % 2 === 0 ? (isHovered ? 37 : 38) : 37;

  const ActiveIndicator = () => {
    return (
      <div
        className="absolute z-10 flex flex-col items-start gap-8 xs:gap-12 lg:gap-0"
        role="button"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        tabIndex={-2}
      >
        {data?.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              index !== activeIndex &&
                swiperRef.current?.slideToLoop(index, 3000, false);
            }}
            className={twMerge(
              'flexCenter h-3 w-9 cursor-pointer lg:mb-[51px]',
              index === data.length - 1 && 'mb-0'
            )}
          >
            <div
              className={twMerge(
                'h-0.75 w-full bg-neutral500',
                index === activeIndex && 'bg-brandPrimary'
              )}
            />
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="flexCenter relative w-full origin-center -rotate-90 flex-col lg:h-[675px] lg:max-w-9 lg:rotate-0 lg:overflow-hidden">
      <div
        tabIndex={-1}
        role="button"
        className={twMerge(
          'absolute flex flex-col justify-between transition-all duration-250 ease-linear',
          isHovered
            ? 'h-[565px]'
            : 'h-72 xs:h-[28rem] sm:h-[30rem] md:h-[40rem] lg:h-[765px]'
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {Array.from({ length: numberOfLines }).map((_, index) => (
          <div
            key={index}
            className={twMerge(
              'mx-auto h-px w-6 bg-neutral700',
              index < 6 && 'opacity-40',
              index >= 6 && index < 11 && 'opacity-70',
              index > 23 && 'opacity-80',
              index > 28 && 'opacity-40'
            )}
          />
        ))}
      </div>
      <ActiveIndicator />
    </div>
  );
};

export default SwiperNavigation;
