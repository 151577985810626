import { Dispatch, ReactNode, SetStateAction } from 'react';

import { twMerge } from 'tailwind-merge';

import FilterToggleButton from '@components/filters/filterToggleButton';

import { CloseSidebarIcon } from '@shared/assets';
import { disableScrollOnBody } from '@shared/utils';

interface IProps {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
  closeButtonText: string;
  closeButtonClassName?: string;
}

// Can be used to wrap items that will turn into a sidebar on mobile
const MobileSidebar = ({
  children,
  isOpen,
  className,
  setIsOpen,
  closeButtonText,
  closeButtonClassName,
}: IProps) => {
  disableScrollOnBody(isOpen, true);

  return (
    <div
      className={twMerge(
        'top-0 z-50 transition-all duration-300 ease-out max-md:fixed max-md:h-dvh max-md:w-screen md:h-auto',
        isOpen
          ? `flex flex-col opacity-100 max-md:right-0 md:z-0`
          : 'right-full opacity-0 max-md:pointer-events-none md:opacity-100',
        className
      )}
    >
      <FilterToggleButton
        setIsOpen={setIsOpen}
        Icon={CloseSidebarIcon}
        title={closeButtonText}
        className={twMerge(
          'ml-auto',
          isOpen && 'border-r-0',
          closeButtonClassName
        )}
      />
      {children}
    </div>
  );
};

export default MobileSidebar;
