import { useQuery } from '@tanstack/react-query';

import { FEATURED_PRODUCTS } from '@shared/constants/queryKeys';

import { marketplaceApi } from '../marketplaceApi';

const useGetFeaturedProductsQuery = () =>
  useQuery({
    queryKey: [FEATURED_PRODUCTS],
    queryFn: () => marketplaceApi.client.getFeaturedProducts(),
  });

export default useGetFeaturedProductsQuery;
