'use client';

import { twMerge } from 'tailwind-merge';

interface IProps {
  isEnabled: boolean;
  setIsEnabled: (arg: boolean) => void;
}

const ToggleButton = ({ isEnabled, setIsEnabled }: IProps) => {
  return (
    <div
      onClick={() => setIsEnabled(!isEnabled)}
      className="cursor-pointer"
      role="button"
      tabIndex={-3}
    >
      <div
        className={twMerge(
          'inline-flex h-[18px] w-[30px] items-center rounded-full bg-neutral700 transition duration-300 ease-out active:bg-neutral500 hover:bg-neutral600',
          isEnabled && 'bg-green900'
        )}
      >
        <span
          className={twMerge(
            'size-3 rounded-full bg-neutral300 transition duration-300 ease-out',
            isEnabled ? 'translate-x-[14px] bg-green500' : 'translate-x-1'
          )}
        />
      </div>
    </div>
  );
};

export default ToggleButton;
