import { ISvgImport } from '@interfaces/svg';
import { twMerge } from 'tailwind-merge';

interface IProps {
  text: string;
  icon: ISvgImport;
  className?: string;
  onClick: () => void;
}

const ButtonIcon = ({ icon: Icon, text, className, onClick }: IProps) => {
  const textAndBorderStyles =
    'roundedBlTr whitespace-nowrap label-medium uppercase text-neutral400 border border-neutral700 hover:border-neutral600 hover:text-white ';

  return (
    <button
      className={twMerge(
        'group relative flex h-10 w-full items-center justify-between gap-3 px-3 transition-all duration-300 ease-out hover:bg-neutral800',
        textAndBorderStyles,
        className
      )}
      onClick={onClick}
    >
      {text}
      <Icon className="absolute right-2 top-1/2 -translate-y-1/2 text-neutral400 transition-all group-hover:text-white" />
    </button>
  );
};

export default ButtonIcon;
