import { ActiveFilters } from '@interfaces';

import useGetMarketplaceTagsQuery from '@api/user/marketplace/queries/useGetMarketplaceTagsQuery';
import useGetProductCategoriesQuery from '@api/user/marketplace/queries/useGetProductCategoriesQuery';

import ToggleButton from '@components/ToggleButton/ToggleButton';

import FilterGroup from '../productsFilterGroup';

interface IProps {
  activeTabId?: number;
  activeFilters: ActiveFilters;
  setActiveFilters: (activeFilters: ActiveFilters) => void;
  productTypeId: number;
}

const ProductsFilterSidebarContent = ({
  activeFilters,
  activeTabId,
  productTypeId,
  setActiveFilters,
}: IProps) => {
  const { data: productCategories } =
    useGetProductCategoriesQuery(productTypeId);

  const { data: marketplaceTags } = useGetMarketplaceTagsQuery();

  return (
    <div className="bg-gray-200 flex flex-col gap-6">
      {productCategories && (
        <FilterGroup
          title="category"
          filters={productCategories}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          hasShowMore={productCategories.data.length > 5}
          activeTabId={activeTabId}
        />
      )}
      {marketplaceTags && (
        <FilterGroup
          title="tags"
          filters={marketplaceTags}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          hasShowMore={marketplaceTags.data.length > 5}
        />
      )}
      <div className="flex w-full items-center justify-between border-b border-neutral700 pb-6 pl-10 pr-4">
        <span className="font-medium text-neutral300">Free trial</span>
        <ToggleButton
          isEnabled={activeFilters.isFreeTrial === 'true' ? true : false}
          setIsEnabled={(state) => {
            const copiedActiveFilters = {
              ...activeFilters,
            };

            if (state) {
              copiedActiveFilters.isFreeTrial = 'true';
            } else {
              copiedActiveFilters.isFreeTrial = '';
            }

            setActiveFilters(copiedActiveFilters);
          }}
        />
      </div>
    </div>
  );
};
export default ProductsFilterSidebarContent;
