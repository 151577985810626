import { ReactElement, cloneElement, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { ActiveFilters, IDropdownOption } from '@interfaces';
import { twMerge } from 'tailwind-merge';

import ButtonIcon from '@components/buttonIcon';
import SingleSelectDropdown from '@components/dropdowns/SingleSelectDropdown';
import FilterToggleButton from '@components/filters/filterToggleButton';
import MobileSidebar from '@components/mobileSidebar';

import { Enter } from '@shared/assets';
import useGetWindowWidth from '@shared/hooks/useGetWindowWidth';

import { SELECT_FILTER } from '../constants';
import SelectedProductsFilters from '../selectedProductFilters';
import ProductsFilterSidebarContent from './ProductsFilterSidebarContent';

interface Props {
  children: ReactElement;
  activeTabId?: number;
  selectedSortOption: IDropdownOption;
  onSelectSortChange: (option: IDropdownOption) => void;
  activeFilters: ActiveFilters;
  setActiveFilters: (activeFilters: ActiveFilters) => void;
  productTypeId: number;
  totalItems?: number;
}

const ProductsFilterSidebar = ({
  children,
  activeFilters,
  activeTabId,
  selectedSortOption,
  onSelectSortChange,
  setActiveFilters,
  productTypeId,
  totalItems,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  //TODO temp fix for bug see if can be achieved without width listener
  const windowWidth = useGetWindowWidth();

  return (
    <div className="mt-16">
      <div
        id="browse-products"
        className="flex flex-col items-start justify-between gap-4 px-6 sm:flex-row sm:items-center xl:px-22.5"
      >
        <div className="flex items-center gap-3">
          <h2 className="heading-xxsmall">Browse AI products</h2>
          <p className="label-small mt-1 text-neutral400">
            {totalItems} results
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-between gap-3 xs:flex-row sm:w-max sm:items-center">
          <SingleSelectDropdown
            selectedOption={selectedSortOption}
            options={SELECT_FILTER}
            onChange={onSelectSortChange}
            dropdownBtnClassName="w-[185px]"
            selectedItemLabel="Sort"
          />
          <ButtonIcon
            icon={Enter}
            text="Show Filters"
            className="w-[11.5rem] sm:hidden"
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>

      <div className="mb-10 mt-6 flex items-center justify-between pr-6 xl:pr-22.5">
        <FilterToggleButton
          setIsOpen={setIsOpen}
          Icon={Enter}
          title="Show filters"
          className={twMerge(
            'max-sm:hidden',
            isOpen ? 'pointer-events-none opacity-0' : 'opacity-100',
            'border-l-0 transition-all duration-300'
          )}
        />
        <SelectedProductsFilters
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          activeTabId={activeTabId}
        />
      </div>
      <div className="relative flex min-h-[800px] overflow-hidden">
        <MobileSidebar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          closeButtonText="Hide filters"
          className={twMerge(
            'z-99 flex flex-col gap-6 overflow-hidden border-t border-neutral700 bg-neutral900 pb-26.875rem md:border-r',
            isOpen
              ? `max-md:border-t-0 md:w-[250px] xl:w-[300px]`
              : 'overflow-hidden md:w-0'
          )}
        >
          <RemoveScroll enabled={isOpen && windowWidth < 768}>
            <ProductsFilterSidebarContent
              activeFilters={activeFilters}
              productTypeId={productTypeId}
              setActiveFilters={setActiveFilters}
              activeTabId={activeTabId}
            />
          </RemoveScroll>
          <div className="absolute left-[-148px] top-[910px] hidden h-[427px] w-[427px] rounded-full bg-brandPrimary opacity-10 blur-[100px] lg:block" />
        </MobileSidebar>
        {cloneElement(children, {
          isSidebarOpen: isOpen,
        })}
      </div>
    </div>
  );
};

export default ProductsFilterSidebar;
